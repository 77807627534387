<template>
  <div class="section-title">
    <BaseImg v-if="background" src="/assets/images/common/bg-section-title.svg" class="section-title__bg" />
    <BaseImg v-if="icon" :src="icon" class="section-title__icon" />
    <div class="section-title__wrapper">
      <p class="title" :class="{ dot: title.toUpperCase() === 'LIVE CASINO' }">{{ title }}</p>
      <BaseImg :src="underline || underlineDefault" />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseImg from '~/components/common/base-img.vue'
const underlineDefault = '/assets/images/common/underline.svg'

const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  underline: {
    type: String,
    default: ''
  },
  background: {
    type: Boolean,
    default: false
  }
})

const { icon, title, underline } = toRefs(props)
</script>

<style lang="scss" scoped src="assets/scss/components/common/section-title-home.scss" />
