import { useHotMatchApi } from '@/api/hotmatch'
import { useLoading } from '@/composables/useLoading'

export function useHotMatch() {
  const { isLoading, loading, load } = useLoading()
  const { $axios, $device, $router, $config } = useNuxtApp()

  const { GET_HOT_MATCH, GET_LINK_K_SPORT, GET_LINK_C_SPORT, GET_LINK_P_SPORT } = useHotMatchApi()
  const fetchHotMatch = async (limit: number) => {
    loading()
    try {
      const payload = {
        params: {
          limit
        }
      }
      const { data: response } = await $axios.get(GET_HOT_MATCH, payload)
      if (response.code === 200) {
        load()
        return response.data
      }
    } catch (error) {
      load()
      console.log(error)
    }
    load()
  }
  const getLink = async (api: string, payload: any) => {
    loading()
    try {
      const { data: response } = await $axios.get(api, payload)
      load()
      return response.status === 'OK' ? response.data.url : ''
    } catch (error) {
      load()
    }
  }
  const getReturnKsport = async (item: any) => {
    const payload = {
      params: {
        matchId: item.match_id,
        leagueId: item.league_id,
        live: item.live
      }
    }
    if ($device.isMobileOrTablet) {
      return await getLink(GET_LINK_K_SPORT, payload)
    }
    return `/the-thao/ksports?match_id=${item.match_id}&league_id=${item.league_id}&live=${item.live}`
  }
  const getLink_K_SportHome = async (item: any) => {
    const returnUrl = await getReturnKsport(item)
    const payload = {
      params: {
        matchId: item.match_id,
        leagueId: item.league_id,
        live: item.live,
        utm_source: $config.BRAND_NAME,
        loginPath: `?login=true&returnUrl=${returnUrl}`,
        registerPath: `?register=true&returnUrl=${returnUrl}`
      }
    }
    return await getLink(GET_LINK_K_SPORT, payload)
  }
  const getLink_K_Sport = async (item: any) => {
    const payload = {
      params: {
        matchId: item.match_id,
        leagueId: item.league_id,
        live: item.live
      }
    }
    try {
      const openNewTab = window.open('about:blank', '_blank')
      const response = await $axios.$get(GET_LINK_K_SPORT, payload)
      if (response.status === 'OK') {
        if (response.data.maintenance) {
          openNewTab.location.href = '/maintenance'
          return
        }
        openNewTab.location.href = response.data.url
      } else {
        openNewTab?.close()
      }
    } catch (error) {
      console.error(error)
    }
  }
  const playKsport = (item: any, newTab = true) => {
    const matchId = item.match_id
    const leagueId = item.league_id
    const live = item.live || ''
    if ($device.isMobileOrTablet) {
      getLink_K_Sport({ match_id: matchId, league_id: leagueId, live })
    } else if (newTab) {
      const routeData = $router.resolve({
        path: '/ksports',
        query: { match_id: matchId, league_id: leagueId, live }
      })
      window.open(routeData.href, '_blank')
    } else {
      $router.push({
        path: '/the-thao/ksports',
        query: { match_id: matchId, league_id: leagueId, live }
      })
    }
  }
  const getLink_C_SportHome = async (item) => {
    const payload = {
      params: {
        matchId: item.match_id,
        leagueId: item.league_id,
        live: item.live,
        loginPath: '?login=true',
        registerPath: '?register=true',
        utm_source: 'keo_tot_the_thao'
      }
    }
    return await getLink(GET_LINK_C_SPORT, payload)
  }
  const getLink_P_SportHome = async (item) => {
    const payload = {
      params: {
        matchId: item.match_id,
        leagueId: item.league_id,
        live: item.live,
        loginPath: '?login=true',
        registerPath: '?register=true',
        utm_source: 'keo_tot_the_thao'
      }
    }
    return await getLink(GET_LINK_P_SPORT, payload)
  }
  const getLink_C_Sport = async (item) => {
    const payload = {
      params: {
        matchId: item.athena_match_id,
        leagueId: item.athena_league_id,
        live: item.live
      }
    }
    try {
      const openNewTab = window.open('about:blank', '_blank')

      const { data: response } = await $axios.get(GET_LINK_C_SPORT, payload)

      if (response.status === 'OK') {
        if (response.data.maintenance) {
          openNewTab.location.href = '/maintenance'
          return
        }
        openNewTab.location.href = response.data.url
      } else {
        openNewTab.close()
      }
    } catch (error) {
      console.error(error)
    }
  }
  const playCsport = (item, newTab = true) => {
    const matchId = item.athena_match_id
    const leagueId = item.athena_league_id
    const live = item.live || ''
    if ($device.isMobileOrTablet) {
      getLink_C_Sport({ match_id: matchId, league_id: leagueId, live })
    } else if (newTab) {
      const routeData = $router.resolve({
        path: '/csports',
        query: { match_id: matchId, league_id: leagueId, live }
      })
      window.open(routeData.href, '_blank')
    } else {
      $router.push({
        path: '/csports',
        query: { match_id: matchId, league_id: leagueId, live }
      })
    }
  }
  return {
    isLoading,
    fetchHotMatch,
    playKsport,
    getLink_K_Sport,
    getLink_K_SportHome,
    getLink_C_SportHome,
    getLink_P_SportHome,
    playCsport,
    getLink_C_Sport
  }
}
